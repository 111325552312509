var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import AppRouters from "./routers";
import Modal from 'react-modal';
import { restoreLogin } from "./services/auth.service";
import { wssProvider } from "./services/websocket.service";
import { env } from "./constants/global.constants";
import { getRaffleDraws } from "./services/game.service";
import { raffleDraws, raffleDrawSubject } from "./store/games.store";
import ModalService from "./services/modal.service";
import { auth } from "./store/auth.store";
import { cartToggleSubject } from "./store/cart.store";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ApiService } from "./services/http.service";
import { recoverCart } from "./services/cart.service";
import { getAgreements, getNews, getSiteSettings, useForceUpdate } from "./services/misc.functions";
import { Storage } from "./services/localstorege.service";
import OneSignal from "react-onesignal";
import paths from "./paths";
import { AuthContext, loginSubject } from "./store/login.store";
import logoESans from "./assets/images/logo-renkli.png";
export var wsProvider;
// @ts-ignore
// const FirstApp = lazy(() => import("FIRST_APP/app"));
export function isOnline() {
    // console.log("%c Internet connected up again :)", successLogStyle);
}
export function isOffline() {
    // console.log("%c Ops, Connection lost ! :(", errorLogStyle)
    if (wsProvider && wsProvider.readyState === 1) {
        wsProvider.close();
    }
}
export var recoveredCartItems = [];
var cart = Storage.get('cartStore');
if (cart) {
    recoveredCartItems.push.apply(recoveredCartItems, cart.items.filter(function (el) { return el.itemType === 'raffle'; }).map(function (el) { return el.item.id; }));
}
var loadingRaffleDraws = false;
function PreLoad() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: 
                // console.log('HERE');
                return [4 /*yield*/, getSiteSettings()];
                case 1:
                    // console.log('HERE');
                    _a.sent();
                    return [4 /*yield*/, restoreLogin()];
                case 2:
                    _a.sent();
                    if (!wsProvider) {
                        wsProvider = wssProvider.connect(env.accounting_wss);
                    }
                    if (raffleDraws.length === 0 && !loadingRaffleDraws) {
                        loadingRaffleDraws = true;
                        getRaffleDraws().then(function () {
                            recoverCart();
                        });
                    }
                    getAgreements();
                    getNews();
                    return [2 /*return*/];
            }
        });
    });
}
var width = window.innerWidth;
export var isMobile = width < 986 ? true : false;
// screen.orientation.lock("portrait-primary");
window.addEventListener('locationchange', function () {
    // console.log('location changed!', window.location.pathname);
    raffleDrawSubject.next({ action: 'close' });
});
window.addEventListener('popstate', function () {
    window.dispatchEvent(new Event('locationchange'));
});
var App = function () {
    var _a = useState(false), isAuthenticated = _a[0], setIsAuthenticated = _a[1];
    var _b = useState(true), isLoading = _b[0], setLoading = _b[1];
    useEffect(function () {
        if (auth.member && auth.member.id) {
            OneSignal.init({ appId: "5c306ecf-1db9-43ff-aea4-dd0fed560b78" }).then(function () {
                OneSignal.showSlidedownPrompt().then(function () {
                    // console.log('HERE 2');
                });
                OneSignal.setExternalUserId(auth.member.id.toString()).then(function (res) {
                    console.log(res);
                });
                OneSignal.getUserId().then(function (res) {
                    console.log(res);
                    var os = "Unknown OS";
                    if (navigator.userAgent.indexOf("Win") != -1)
                        os = "Windows";
                    if (navigator.userAgent.indexOf("Mac") != -1)
                        os = "Macintosh";
                    if (navigator.userAgent.indexOf("Linux") != -1)
                        os = "Linux";
                    if (navigator.userAgent.indexOf("Android") != -1)
                        os = "Android";
                    if (navigator.userAgent.indexOf("like Mac") != -1)
                        os = "iOS";
                    var browserType = "Unknown Browser Type";
                    if (navigator.userAgent.indexOf("Safari") != -1)
                        browserType = "Safari";
                    if (navigator.userAgent.indexOf("Chrome") != -1)
                        browserType = "Chrome";
                    if (navigator.userAgent.indexOf("OPR") != -1)
                        browserType = "Opera";
                    if (navigator.userAgent.indexOf("Firefox") != -1)
                        browserType = "Firefox";
                    var deviceType = "Desktop";
                    if (navigator.appVersion.indexOf("Mobile") > -1)
                        deviceType = "Mobile";
                    var params = {
                        token: Storage.get('token'),
                        device_id: res,
                        device_type: deviceType,
                        device_os: os,
                        browser_os: browserType,
                    };
                    var api = new ApiService();
                    api.start('post', env.accounting_api + '/member/api/register-device/', params, true)
                        .then(function (res) {
                        console.log(res);
                    });
                });
                OneSignal.addListenerForNotificationOpened(function (r) {
                    // console.log('HEREEEEE^^^^^');
                    try {
                        var additionalDatas = r.notification.additionalData;
                        if (additionalDatas != null) {
                            if (additionalDatas.include('campaign')) {
                                window.location.href = paths.campaign.url;
                            }
                        }
                    }
                    catch (e) {
                        console.log('Onesignal Error: ' + e);
                    }
                });
            });
        }
    }, [auth.authentication]);
    var forceUpdate = useForceUpdate();
    PreLoad().then(function () {
        setLoading(false);
    });
    useEffect(function () {
        var loginSubject_subscription;
        if (!isAuthenticated) {
            // eğer token varsa otomatik auth icin wss i restore etmek icin var.
            loginSubject_subscription = loginSubject.subscribe(function (member) {
                if (member.id !== undefined && member.status === 1) {
                    setIsAuthenticated(true);
                }
            });
        }
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    }, []);
    useEffect(function () {
        window.addEventListener("online", isOnline, false);
        window.addEventListener("offline", isOffline, false);
        return function () {
            window.removeEventListener('online', isOnline);
            window.removeEventListener('offline', isOffline);
        };
    }, []);
    useEffect(function () {
        window.addEventListener('resize', resizeWindow);
        return function () {
            window.removeEventListener('resize', resizeWindow);
        };
    }, []);
    var resizeWindow = function () {
        var width = window.innerWidth;
        var nowIsMobileView = width < 986 ? true : false;
        if (isMobile !== nowIsMobileView) {
            isMobile = width < 986 ? true : false;
            forceUpdate();
        }
    };
    // const stickyHeader = () => {
    //
    //   var header = document.getElementById("myHeader");
    //   var sticky = header?.offsetTop;
    //
    //   if (window.pageYOffset > sticky!) {
    //     header!.classList.add("sticky");
    //   } else {
    //     header!.classList.remove("sticky");
    //   }
    //
    // }""
    // useEffect(() => {
    //   window.onscroll = function() {stickyHeader()};
    //   return () => {
    //     window.onscroll = function() {stickyHeader()};
    //   };
    // }, []);
    if (isMobile) {
        cartToggleSubject.next({ action: 'visibleToggleCart', show: false });
    }
    // @ts-ignore
    Modal.setAppElement(document.getElementById('root'));
    return (_jsx(AuthContext.Provider, __assign({ value: { isAuthenticated: isAuthenticated, setIsAuthenticated: setIsAuthenticated } }, { children: isLoading ? _jsx("div", __assign({ className: "App splash" }, { children: _jsx("img", { src: logoESans, width: 180 }) })) :
            _jsxs("div", { children: [_jsx(ModalService, { children: _jsx(AppRouters, {}) }), _jsx(ToastContainer, { closeOnClick: true, pauseOnHover: false, draggable: true, theme: 'light', autoClose: 5000 })] }) })));
};
export default App;
