var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./papara.coupons.css";
import { env } from "../../constants/global.constants";
import { api } from "../../services/http.service";
import { toast } from "react-toastify";
import { dateTimeFormat, loadingAnimation } from "../../services/misc.functions";
import { useNavigate } from "react-router";
import paths from "../../paths";
import { auth } from "../../store/auth.store";
import paparaLogo from "../../assets/images/papara_logo.jpg";
export function PaparaCoupons() {
    var _this = this;
    var _a = useState(""), referenceCode = _a[0], setReferenceCode = _a[1];
    var _b = useState([]), dataList = _b[0], setDataList = _b[1];
    var _c = useState(0), page = _c[0], setPage = _c[1];
    var navigate = useNavigate();
    var handlerCheckReferenceCode = function (paramsCode) {
        if (paramsCode === void 0) { paramsCode = null; }
        var code = paramsCode != null ? paramsCode : referenceCode;
        if (code) {
            api
                .start("post", env.accounting_api + "/accounting/papara/api/use-coupon-code/", { code: code }, true)
                .then(function (res) {
                if (res.status) {
                    loadData(0);
                    setReferenceCode('');
                    toast.success(res.message);
                }
                else {
                    toast.error(res.message);
                }
            })
                .catch(function (error) {
                console.error("API isteği başarısız oldu:", error);
            }).finally(function () {
                window.history.pushState(null, "", paths.paparaCoupon.url);
            });
        }
    };
    useEffect(function () {
        setTimeout(function () {
            if (auth.member) {
                loadData();
                var urlParams = new URLSearchParams(window.location.search);
                var code = urlParams.get('code');
                if (code != null) {
                    handlerCheckReferenceCode(code);
                }
            }
            else {
                navigate("".concat(paths.login.url, "/?n=").concat(paths.paparaCoupon.url));
            }
        }, 500);
    }, []);
    var loadData = function (force) {
        if (force === void 0) { force = null; }
        return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadingAnimation(true);
                        if (page === 0 || force === 0) {
                            setDataList([]);
                            setPage(0);
                        }
                        payload = {
                            metadata: {
                                page: force != null ? force : page,
                            },
                            trans_type: "38",
                        };
                        return [4 /*yield*/, api
                                .start("post", env.accounting_api + "/member/api/list-transaction/", payload, true)
                                .then(function (res) {
                                loadingAnimation(false);
                                setPage(page + 1);
                                if (res && res.status) {
                                    dataList.push.apply(dataList, res.data);
                                    setDataList(dataList);
                                }
                                if (res && res.metadata) {
                                    var id_datalist_loadmore = document.getElementById("id_datalist_loadmore");
                                    if (res.metadata.total_page <= page + 1) {
                                        id_datalist_loadmore.style.display = "none";
                                    }
                                    else {
                                        id_datalist_loadmore.style.display = "block";
                                    }
                                }
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    var loadMore = function (e) {
        e.target.disabled = true;
        loadData().finally(function () {
            e.target.disabled = false;
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "gift-ticket-content" }, { children: _jsxs("div", __assign({ className: "gift-ticket-info" }, { children: [_jsx("p", __assign({ className: "gift-ticket-title" }, { children: "Kupon Kodu Ekle" })), _jsx("p", __assign({ className: 'text-center' }, { children: "Papara dan ald\u0131\u011F\u0131n\u0131z kupon kodunu girerek hesab\u0131n\u0131za y\u00FCkleme yapabilirsiniz." })), _jsxs("div", __assign({ className: "gift-ticket-button" }, { children: [_jsx("input", { value: referenceCode, onChange: function (e) { return setReferenceCode(e.target.value); }, className: "form-control py-2 gift-ticket-input text-center", placeholder: "Kupon kodunuzu giriniz" }), _jsx("button", __assign({ className: "btn btn-green ms-1", onClick: function () {
                                        handlerCheckReferenceCode();
                                    } }, { children: "Y\u00FCkle" }))] }))] })) })), _jsxs("div", __assign({ className: "card rounded-4 mt-4" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "Kupon Kodlar\u0131m" }) })), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("div", __assign({ className: "my-raffle-order-item papara-coupon row d-flex justify-content-center m-0" }, { children: dataList.map(function (el, key) {
                                    return _jsxs("div", __assign({ className: "row item col-sm-12 col-lg-6" }, { children: [_jsx("div", __assign({ className: "col-4 d-flex column-center" }, { children: _jsx("img", { className: "p-2", src: paparaLogo, width: 60 }) })), _jsxs("div", __assign({ className: "col-8 d-flex justify-content-end flex-column" }, { children: [_jsxs("span", __assign({ className: "d-block text-end", style: { fontSize: "14px" } }, { children: ["Kullan\u0131m Tarihi:", _jsx("br", {}), _jsxs("span", __assign({ style: { color: "blue" } }, { children: ["  ", dateTimeFormat(el.transaction_date)] }))] })), _jsxs("span", __assign({ className: "text-success fw-bold text-end" }, { children: [el.amount, " \u015EP"] }))] }))] }), "item-my-raffle-order-".concat(key));
                                }) })), dataList && dataList.length === 0 && (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "text-center py-5" }, { children: "Listelenecek hi\u00E7 kay\u0131t yok." })) })), _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("div", { id: "id_loaderAnimation", className: "loader" }), _jsx("button", __assign({ id: "id_datalist_loadmore", onClick: loadMore, type: "button", className: "btn rounded-pill btn-outline-primary" }, { children: "Devam\u0131n\u0131 Y\u00FCkle" }))] }))] }))] }))] }));
}
